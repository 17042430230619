import React, { Component } from "react";
import styled from "styled-components";
import TextInputField from "../TextInputField";

class CartConfig extends Component {
  render() {
    let { config, updateConfig = () => 0, isEditable = false } = this.props;

    if (!config) {
      return null;
    }

    if (isEditable) {
      return (
        <Wrapper>
          <p className="title">
            收件人資訊 <span>*為必填欄位</span>
          </p>
          <TextInputField
            label="姓名"
            isRequired
            value={config.name || ""}
            onChange={name => updateConfig({ ...config, name })}
          />
          <TextInputField
            label="信箱"
            isRequired
            value={config.email || ""}
            onChange={email => updateConfig({ ...config, email })}
          />
          <p className="hint">◎格式為abc@gmail.com且不得有空白</p>
          <p className="hint">
            ◎提醒您，請務必確認此電子信箱可以正常收發信件。
          </p>
          <TextInputField
            label="地址"
            isRequired
            value={config.address || ""}
            onChange={address => updateConfig({ ...config, address })}
          />
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <p className="title">收件人資訊</p>
        <div className="confirm-text-container">
          <div className="row margin-top-10">
            <span className="label">姓名 / </span>
            <span className="value">{config.name}</span>
          </div>
          <div className="row margin-top-10">
            <span className="label">信箱 / </span>
            <span className="value">{config.email}</span>
          </div>
          <div className="row margin-top-10">
            <span className="label">地址 / </span>
            <span className="value">{config.address}</span>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;

  & > .title {
    margin-top: 52px;
    font-size: 16px;
    color: #575757;
  }

  & > .hint {
    font-size: 14px;
    color: #a4a4a4;
  }

  & > .confirm-text-container {
    padding-left: 10px;
    border-left: 3px solid lightgrey;
    & .row {
      font-size: 16px;
      color: grey;
      & > span {
        display: block;
      }
    }
  }

  & .margin-top-10 {
    margin-top: 10px;
  }
`;

export default CartConfig;
