import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import TextInputField from "../../Components/TextInputField";
import { withPage } from "../../Page";
import AppConfig from "../../Config/Tel25sms";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Icon from "../../Components/Icon";
import * as Widget from "../../Components/Widget";
import * as Widget2 from "../../Components/Widget2";
import OrderStatusBar from "../../Components/OrderStatusBar";
import OrderBasket from "../../Components/OrderBasket";
import CartConfig from "../../Components/CartConfig";
import OrderPaymentInfo from "../../Components/OrderPaymentInfo";
import OrderBuyerInfo from "../../Components/OrderBuyerInfo";
import PaymentButton from "../../Components/PaymentButton";
import Image from "../../Components/Image";
import { Config } from "@jest/types";
import { TextColor } from "styled-icons/icomoon";
import * as FormUtil from "../../Utils/FormUtil";

const UI_STATE = {
  CART: "CART",
  PAYMENT: "PAYMENT"
};

const UI_STATE_TO_STEP_MAP = {
  CART: 1,
  PAYMENT: 2
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;

  & > .container {
    flex: 1;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1200px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-start;
    }

    & > .left-section {
      width: 621px;
      margin-right: 100px;
      @media screen and (max-width: 1200px) {
        flex: 1;
        margin-right: 0;
      }
      @media screen and (max-width: 767px) {
        width: 335px;
      }
      @media screen and (max-width: 374px) {
        width: 300px;
      }

      & > .info {
        width: 100%;
        height: 100%;

        & > .title {
          margin-top: 52px;
          font-size: 16px;
          color: #575757;

          & > span {
            font-size: 14px;
            color: #f92b2b;
            margin-left: 10px;
          }
        }

        & > .hint {
          font-size: 14px;
          color: #a4a4a4;
        }

        & > .hint-wrapper {
          margin-top: 30px;
          margin-bottom: 46px;
          padding: 30px;
          border: 1px solid #dadada;
          @media screen and (max-width: 767px) {
            padding: 20px;
          }

          & > p {
            font-size: 14px;
            color: ${AppConfig["global"].themeColor};
          }
        }
      }
    }

    & > .right-section {
      width: 400px;

      @media screen and (max-width: 1200px) {
        width: 620px;
        margin-bottom: 60px;
      }
      @media screen and (max-width: 767px) {
        width: 335px;
      }
      @media screen and (max-width: 374px) {
        width: 300px;
      }
    }
  }

  & > .footer {
    height: 120px;
    display: flex;
    background-color: ${AppConfig["global"].themeColor};
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 160px;
    @media screen and (max-width: 1200px) {
      padding: 0 65px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }

    & > button {
      width: 160px;
      height: 40px;
      border: 0;
      font-size: 14px;
      color: ${AppConfig["global"].themeColor};
      background-color: #fff;
      cursor: pointer;
      outline: 0;

      &:disabled {
        background-color: #a9a9a9;
        color: #fff;
        opacity: 0.9;
        cursor: not-allowed;
      }
    }
  }

  ${AppConfig["Templates/CartPage"].css || ""}
`;

const ErrorHint = styled.p`
  margin-bottom: 10px;
  color: white;
  text-align: center;
  font-size: 14px;
`;

class CartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uiState: UI_STATE.CART,
      paymentConfig: {
        paymentType: "ecpay"
      },
      subscribeConfig: {
        is_subscribe_order: false,
        exec_times: 0
      },
      order: null,
      error: ""
    };
  }

  componentDidMount() {
    let { appActions } = this.props;
    appActions.getCart();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    let { appActions, profile } = this.props;
    if (profile !== prevProps.profile) {
      appActions.getCart();
    }
  }

  render() {
    let {
      cartData,
      localCartDataConfig,
      appActions,
      navActions,
      profile
    } = this.props;
    let {
      paymentConfig,
      uiState,
      loading,
      order,
      subscribeConfig,
      error
    } = this.state;
    if (!profile) {
      return <Wrapper>請先登入！</Wrapper>;
    }

    if (cartData && cartData.items.length === 0) {
      return (
        <Wrapper>
          <Widget2.Center className="empty-cart" css="margin:10%">
            <h2 style={{ fontWeight: "unset", margin: "20 15" }}>
              目前您的購物車為空
            </h2>
            <Image
              src="../../images/empty-cart.png"
              css={"width: 80px; height: 80px; margin: 30px;"}
            />
            <Widget2.Row>
              <Widget2.Button
                color={AppConfig["global"].themeColor}
                textColor="white"
                fontSize="1rem"
                css="margin:20px 5px;"
                onClick={() => navActions.push("/")}
              >
                返回首頁
              </Widget2.Button>
              <Widget2.Button
                color={AppConfig["global"].themeColor}
                textColor="white"
                fontSize="1rem"
                css="margin:20px 5px;"
                onClick={() => navActions.push("/me")}
              >
                返回會員中心
              </Widget2.Button>
            </Widget2.Row>
          </Widget2.Center>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <div className="container">
          <div className="left-section">
            <OrderStatusBar step={UI_STATE_TO_STEP_MAP[uiState]} />
            <div className="info">
              {(() => {
                switch (uiState) {
                  case UI_STATE.CART:
                    return (
                      <>
                        <CartConfig
                          isEditable
                          config={{
                            ...localCartDataConfig
                          }}
                          updateConfig={data => {
                            this.setState({ error: "" });
                            appActions.editLocalCartConfig(data);
                          }}
                        />
                        <OrderPaymentInfo
                          isEditable
                          config={paymentConfig}
                          updateConfig={data =>
                            this.setState({ paymentConfig: data })
                          }
                        />
                      </>
                    );
                  case UI_STATE.PAYMENT:
                    return (
                      <OrderBuyerInfo order={order} showPaymentBtn={false} />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
          <div className="right-section">
            <OrderBasket
              cartData={cartData}
              editMode={uiState === UI_STATE.CART}
              openAsDefault={true}
              subscribeData={subscribeConfig}
              toConfigSubscribeData={subscribeConfig =>
                this.setState({ subscribeConfig })
              }
            />
          </div>
        </div>

        <div className="footer">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {error != "" && <ErrorHint>{error}</ErrorHint>}
            {uiState !== UI_STATE.PAYMENT ? (
              <Widget.SubmitButton
                text={"下一步"}
                loading={loading}
                type="button"
                onClick={this._submit}
                disabled={!this._isAllowNextStep()}
              />
            ) : (
              <PaymentButton
                order={order}
                css={`
                  color: ${AppConfig.global.themeColor};
                  background-color: white;
                `}
              />
            )}
          </div>
        </div>
      </Wrapper>
    );
  }

  _submit = () => {
    let { appActions, navActions, profile, localCartDataConfig } = this.props;
    let { paymentConfig, uiState, subscribeConfig } = this.state;

    if (uiState === UI_STATE.CART) {
      if (!FormUtil.validateEmail(localCartDataConfig.email)) {
        this.setState({ error: "請檢查電子信箱格式！" });
      } else {
        this.setState({ loading: true });
        appActions
          .editCartConfig(JSON.stringify(localCartDataConfig))
          .then(() =>
            appActions.createOrder({
              currency: "TWD",
              paymentType: paymentConfig.paymentType,
              title: `user-${profile.id}`,

              is_subscribe_order: subscribeConfig.is_subscribe_order,
              exec_times: subscribeConfig.exec_times || 2
            })
          )
          // .then(order => this.setState({ uiState: UI_STATE.PAYMENT, order }))
          .then(order => navActions.push(`/order?id=${order.id}`))
          .catch(err => console.warn(err))
          .then(() => this.setState({ loading: false }));
      }
    }
  };

  _isAllowNextStep = () => {
    let {
      paymentConfig: { paymentType }
    } = this.state;
    let {
      localCartDataConfig: { name, address, email }
    } = this.props;
    return name && address && email && paymentType;
  };
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      cartData: Selectors.getCartData(state),
      localCartDataConfig: Selectors.getLocalCartDataConfig(state)
    }),
    ActionCreator
  )(CartPage)
);
