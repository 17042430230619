import React, { Component } from "react";
import styled from "styled-components";
import RadioSelectorGroupField from "../RadioSelectorGroupField";
import AppConfig from "../../Config";

const ALL_SUPPORTED_PAY_TYPE = {
  ecpay: {
    label: "綠界付款(線上刷卡或超商代碼繳費)",
    value: "ecpay"
  }
};

class OrderPaymentInfo extends Component {
  render() {
    let { config, updateConfig = () => 0, isEditable = false } = this.props;
    let options = AppConfig.global.supportedPayType.map(
      t => ALL_SUPPORTED_PAY_TYPE[t]
    );
    return (
      <Wrapper>
        <p className="title">選擇付款方式</p>
        {isEditable ? (
          <RadioSelectorGroupField
            value={config.paymentType}
            onChange={paymentType => updateConfig({ ...config, paymentType })}
            options={options}
          />
        ) : (
          <span className="text margin-top-10">
            {options.find(o => o.value === config.paymentType).label}
          </span>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;

  & .margin-top-10 {
    margin-top: 10px;
  }

  & > .title {
    margin-top: 52px;
    font-size: 16px;
    color: #575757;
  }

  & > .hint {
    font-size: 14px;
    color: #a4a4a4;
  }

  & .text {
    font-size: 16px;
    color: grey;
    display: block;
    padding-left: 10px;
    border-left: 3px solid lightgrey;
  }
`;

export default OrderPaymentInfo;
